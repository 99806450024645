import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';

import Layout from '../components/Layout';

const PostPageTemplate = ({ data }) => (
  <Layout>
    <Container>
      <Row>
        <Col sm={12}>
          <h1>{data.markdownRemark.frontmatter.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PostPageTemplate;

export const textPageQuery = graphql`
  query PostPage($id: String!) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        title
      }
    }
  }
`;